<template>
    <div class="ReportMethod full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-6"
                                mandatory
                                id="pathmodal-list"
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-export" :class="{'active': exportGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.exportGraph') }}</p>
                        </div>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''"
                                   @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <v-dialog ref="printdialog" v-model="printmodal" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-print" :class="{'active': printGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.printGraph') }}</p>
                        </div>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="printmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" @click="printReport(); printmodal = false;">
                                {{ $t('footerButton.print') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-big/>
            <div v-if="report.method && report.method.methodName && report.method.methodName.length > 2" class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('methods.methodName') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-method-methodName" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('methods.testingTime') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-method-testingTime" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-maxTestingTimeStatus" disabled
                                       :class="{'active': maxTestingTimeStatus}" class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.maxTestingTime') }}</p>
                    </v-col>
                    <v-col class="col-6">
                        <text-input v-if="maxTestingTimeStatus" class="noAction"
                                    inputName="report-method-maxTestingTime" readonly/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.method != ''">
                    <v-col class="col-4">
                        <p>{{ $t('report.media') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-method-mediaName" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('report.basket') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-method-basket" readonly suffix="Tubes"/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-combinationTest" disabled
                                       :class="{'active': combinationTest}" class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.combinationTest') }}</p>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-4">
                        <p>{{ $t('methods.thresholdHeight') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input class="noAction" inputName="report-method-threshold" readonly suffix="mm"/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-temperatureStatus" disabled
                                       :class="{'active': temperatureStatus}" class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.temperatureLimits') }}</p>
                    </v-col>
                    <v-col class="col-2">
                        <text-input v-if="temperatureStatus" class="noAction" inputName="report-method-temperatureMin"
                                    readonly suffix="°C"/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                    <v-col class="col-2 text-center" :class="{'disabled': !temperatureStatus}">
                        <p>{{ $t('general.to') }}</p>
                    </v-col>
                    <v-col class="col-2">
                        <text-input v-if="temperatureStatus" class="noAction" inputName="report-method-temperatureMax"
                                    readonly suffix="°C"/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-pretestStatus" disabled :class="{'active': pretestStatus}"
                                       class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.pretest') }}</p>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-4" :class="{'disabled': !pretestStatus}">
                        <p>{{ $t('methods.pretestMedia') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input v-if="pretestStatus" class="noAction" inputName="report-method-pretestMediaName"
                                    readonly/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-4" :class="{'disabled': !pretestStatus}">
                        <p>{{ $t('methods.pretestTestingTime') }}</p>
                    </v-col>
                    <v-col class="col-8">
                        <text-input v-if="pretestStatus" class="noAction" inputName="report-method-pretestTestingTime"
                                    readonly/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center" v-if="report.testInfo.testType != 'QuickTest'">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-assessmentStatus" disabled
                                       :class="{'active': assessmentStatus}" class="mr-4"/>
                    </v-col>
                    <v-col class="col-3">
                        <p>{{ $t('methods.assessment') }}</p>
                    </v-col>
                    <v-col class="col-4" :class="{'disabled': !assessmentStatus}">
                        <p>{{ $t('methods.minDisintegrated') }}</p>
                    </v-col>
                    <v-col class="col-1">
                        <text-input v-if="assessmentStatus" class="noAction"
                                    inputName="report-method-assessmentMinDisintegrated" readonly/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                    <v-col class="col-1 text-center" :class="{'disabled': !temperatureStatus}">
                        <p>{{ $t('general.of') }}</p>
                    </v-col>
                    <v-col class="col-1">
                        <text-input v-if="assessmentStatus" class="noAction"
                                    inputName="report-method-assessmentMaxDisintegrated" readonly/>
                        <text-input v-else class="noAction" inputName="" readonly/>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-fastDisintegration" disabled
                                       :class="{'active': fastDisintegration}" class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.fastDisintegration') }}</p>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                    <v-col class="col-2">
                        <switch-on-off name="report-method-withoutDisc" disabled :class="{'active': withoutDisc}"
                                       class="mr-4"/>
                    </v-col>
                    <v-col class="col-4">
                        <p>{{ $t('methods.withoutDisc') }}</p>
                    </v-col>
                </v-row>
<!--                <v-row class="d-flex align-center">-->
<!--                    <v-col class="col-2">-->
<!--                        <switch-on-off name="report-method-manual" disabled :class="{'active': manual}" class="mr-4"/>-->
<!--                    </v-col>-->
<!--                    <v-col class="col-4">-->
<!--                        <p>{{ $t('methods.manual') }}</p>-->
<!--                    </v-col>-->
<!--                </v-row>-->
            </div>
            <div v-else class="content-area pa-0 flex-grow-1 bg-white d-flex flex-column">
                <p class="flex-grow-1 text-center d-flex justify-center align-center pb-6 noMethod"> {{ $t('report.couldNotFindMethod') }} </p>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToReport"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !checkUserRights('reportsDelete')}"
                           @footerButtonClick="deleteReport"></footer-button>
            <footer-button buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress"
                           @footerButtonClick="pathmodal = true"
                           :class="{'disabled': !checkUserRights('reportsExport')}"></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           @footerButtonClick="printmodal = true"
                           :class="{'disabled': !checkUserRights('reportsPrint')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftBig from '@/components/NavigationLeftBig.vue'
import FooterButton from '@/components/FooterButton.vue'
import TextInput from "@/components/TextInput.vue";
import SwitchOnOff from "@/components/SwitchOnOff.vue";
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'ReportMethod',
    components: {
        SwitchOnOff,
        NavigationLeftBig,
        TextInput,
        FooterButton,
    },
    props: {},
    data() {
        return {
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
            printmodal: false,
        }
    },
    computed: {
        ...mapState([
            'report',
            'pathlist',
            'graphOnReport'
        ]),
        exportGraph() {
            return this.$store.state.graphOnReport.export;
        },
        printGraph() {
            return this.$store.state.graphOnReport.print;
        },
        maxTestingTimeStatus() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.maxTestingTimeStatus;
            } else {
                return '';
            }
        },
        combinationTest() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.combinationTest;
            } else {
                return '';
            }
        },
        temperatureStatus() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.temperatureStatus;
            } else {
                return '';
            }
        },
        pretestStatus() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.pretestStatus;
            } else {
                return '';
            }
        },
        assessmentStatus() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.assessmentStatus;
            } else {
                return '';
            }
        },
        fastDisintegration() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.fastDisintegration;
            } else {
                return '';
            }
        },
        withoutDisc() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.withoutDisc;
            } else {
                return '';
            }
        },
        manual() {
            let quicktest = this.report.testInfo.testType;
            if (quicktest != 'QuickTest') {
                return this.$store.state.report.method.manual;
            } else {
                return '';
            }
        }
    },
    methods: {
        goToReport() {
            this.$router.push('report');
        },
        doExport() {
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/export',
                {
                    id: this.report.id,
                    pathId: this.exportPath,
                    includeGraph: this.graphOnReport.export
                }])
                .then(response => {
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(() => {
                            this.showExportSuccess = false;
                        }, 800)
                    }
                });
        },
        printReport() {
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/print',
                {
                    id: this.report.id,
                    includeGraph: this.graphOnReport.print
                }])
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        deleteReport() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/delete', {'id': [this.report.id]}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToReport();
                    }
                })
        },
    },
    mixins: [mixins],
}
</script>
<style scoped lang="scss">

.v-application p.noMethod {
    color: $disiBluegreyDark;
}
</style>